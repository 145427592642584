<template>
  <v-container>
    <perseu-card title="Ordenar pontos de impactação">
      <div slot="content" class="black--text">
        <div class="d-flex flex-column align-center">
          <span class="subtitle-1">
            Ponto de rastreio: {{ reorderDto.trackingPoint.name }}
          </span>
          <v-avatar size="100">
            <v-img :src="reorderDto.trackingPoint.image" />
          </v-avatar>
        </div>
        <v-list two-line>
          <draggable
            class="draggable"
            group="impactionPoints"
            :list="reorderDto.pointsOrder"
          >
            <template v-for="(item, index) in reorderDto.pointsOrder">
              <point-card
                class="mt-2"
                :key="index"
                :point="item.impactionPoint"
                :order="index"
              />
            </template>
          </draggable>
        </v-list>
      </div>
      <div slot="actions">
        <v-btn @click="saveOrder" color="secondary">Salvar</v-btn>
      </div>
    </perseu-card>
  </v-container>
</template>

<script>
import { loadPoints, saveMany } from "@/services/points-order-service.js";
import draggable from "vuedraggable";
import { pagesNames } from "@/router/pages-names";

export default {
  components: {
    "point-card": () => import("@/components/Points/PointCardOrder"),
    draggable,
  },
  data: () => ({
    reorderDto: {
      trackingPoint: {},
      pointsOrder: [],
    },
  }),
  created() {
    this.loadPoints();
  },
  methods: {
    async loadPoints() {
      try {
        const { data } = await loadPoints({
          parId: this.$route.query.parId,
          typeId: this.$route.query.typeId,
        });
        this.reorderDto = data;
      } catch (error) {
        this.$errorHandler(error);
      }
    },
    async saveOrder() {
      try {
        const newArray = this.reorderDto.pointsOrder.map((po, index) => {
          po.order = index;
          delete po.impactionPoint;
          return po;
        });
        await saveMany(newArray);
        this.$toasted.global.defaultSuccess();
        this.$router.push({ name: pagesNames.PARES_BIOMAGNETICOS_LIST });
      } catch (error) {
        this.$errorHandler(error);
      }
    },
  },
};
</script>

<style></style>
